import React, { createContext, useContext, useState } from 'react';

const CharacterContext = createContext(null);


export function CharacterContextProvider({ children, name: _name, stats: _stats }) {
	const [name, setName] = useState(_name || 'PLAYER');
	const [stats, setStats] = useState(_stats || {
		hp: 100,
		mp: 50,
		str: 1,
		def: 1,
		int: 1,
		exp: 0,
		conditions: [],
	});

	const characterProps = {
		name,
		setName,
		stats,
		setStats,
	};

  return (
    <CharacterContext.Provider value={characterProps}>
      {children}
    </CharacterContext.Provider>
  );
}


function useCharacterContext() {
  const context = useContext(CharacterContext);
  if (!context) throw new Error('useCharacterContext needs an CharacterContextProvider');
  return context;
}


export function withCharacterContext(Component) {
  return function CharacterContextComponent(props) {
    return (
      <CharacterContext.Consumer>
        {context => <Component {...props} {...context} />}
      </CharacterContext.Consumer>
    );
  };
}


export default {
  Provider: CharacterContextProvider,
  Context: CharacterContext,
  Consumer: CharacterContext.Consumer,
  useContext: useCharacterContext,
  withContext: withCharacterContext,
};
