import React from 'react';

export default function SpriteRightWalkingOne() {
  return (
    <>
      <path fill="#3f487f" d="M5 0h1v1H5zM4 1h1v1H4z"/>
      <path fill="#fff8ff" d="M5 1h1v1H5z"/>
      <path fill="#3f487f" d="M6 1h1v1H6zM4 2h1v1H4z"/>
      <path fill="#fff8ff" d="M5 2h1v1H5zm1 0h1v1H6z"/>
      <path fill="#3f487f" d="M7 2h1v1H7zm1 0h1v1H8zm1 0h1v1H9zm1 0h1v1h-1zM2 3h1v1H2zm1 0h1v1H3z"/>
      <path fill="#dfe0ef" d="M4 3h1v1H4zm1 0h1v1H5zm1 0h1v1H6zm1 0h1v1H7zm1 0h1v1H8z"/>
      <path fill="#fff8ff" d="M9 3h1v1H9zm1 0h1v1h-1z"/>
      <path fill="#3f487f" d="M11 3h1v1h-1zm1 0h1v1h-1zM1 4h1v1H1z"/>
      <path fill="#dfe0ef" d="M2 4h1v1H2zm1 0h1v1H3zm1 0h1v1H4zm1 0h1v1H5z"/>
      <path fill="#fff8ff" d="M6 4h1v1H6zm1 0h1v1H7zm1 0h1v1H8zm1 0h1v1H9zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#3f487f" d="M12 4h1v1h-1z"/>
      <path fill="#2f3860" d="M13 4h1v1h-1z"/>
      <path fill="#3f487f" d="M0 5h1v1H0z"/>
      <path fill="#dfe0ef" d="M1 5h1v1H1zm1 0h1v1H2zm1 0h1v1H3zm1 0h1v1H4zm1 0h1v1H5zm1 0h1v1H6zm1 0h1v1H7zm1 0h1v1H8z"/>
      <path fill="#2f3860" d="M9 5h1v1H9zm1 0h1v1h-1z"/>
      <path fill="#ff605f" d="M11 5h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#10203f" d="M13 5h1v1h-1z"/>
      <path fill="#3f487f" d="M1 6h1v1H1z"/>
      <path d="M2 6h1v1H2z"/>
      <path fill="#dfe0ef" d="M3 6h1v1H3zm1 0h1v1H4z"/>
      <path fill="#2f3860" d="M5 6h1v1H5zm1 0h1v1H6zm1 0h1v1H7zm1 0h1v1H8zm1 0h1v1H9zm1 0h1v1h-1z"/>
      <path fill="#10203f" d="M11 6h1v1h-1z"/>
      <path fill="#c04040" d="M12 6h1v1h-1zm1 0h1v1h-1z"/>
      <path d="M2 7h1v1H2z"/>
      <path fill="#dfe0ef" d="M3 7h1v1H3z"/>
      <path fill="#10203f" d="M4 7h1v1H4zm1 0h1v1H5z"/>
      <path fill="#2f3860" d="M6 7h1v1H6zm1 0h1v1H7zm1 0h1v1H8zm1 0h1v1H9zm1 0h1v1h-1z"/>
      <path fill="#10203f" d="M11 7h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#7f4040" d="M13 7h1v1h-1z"/>
      <path d="M2 8h1v1H2z"/>
      <path fill="#10203f" d="M3 8h1v1H3zm1 0h1v1H4zm1 0h1v1H5zm1 0h1v1H6zm1 0h1v1H7z"/>
      <path d="M8 8h1v1H8z"/>
      <path fill="#ffd0b0" d="M9 8h1v1H9zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#df9070" d="M12 8h1v1h-1z"/>
      <path fill="#7f4040" d="M13 8h1v1h-1z"/>
      <path d="M2 9h1v1H2z"/>
      <path fill="#10203f" d="M3 9h1v1H3zm1 0h1v1H4zm1 0h1v1H5zm1 0h1v1H6z"/>
      <path d="M7 9h1v1H7z"/>
      <path fill="#df9070" d="M8 9h1v1H8z"/>
      <path fill="#ffd0b0" d="M9 9h1v1H9z"/>
      <path fill="#10203f" d="M10 9h1v1h-1z"/>
      <path fill="#ffd0b0" d="M11 9h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#7f4040" d="M13 9h1v1h-1z"/>
      <path d="M3 10h1v1H3zm1 0h1v1H4z"/>
      <path fill="#df9070" d="M5 10h1v1H5z"/>
      <path fill="#ffc090" d="M6 10h1v1H6z"/>
      <path d="M7 10h1v1H7z"/>
      <path fill="#df9070" d="M8 10h1v1H8z"/>
      <path fill="#ffc090" d="M9 10h1v1H9z"/>
      <path fill="#10203f" d="M10 10h1v1h-1z"/>
      <path fill="#ffd0b0" d="M11 10h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#7f4040" d="M13 10h1v1h-1z"/>
      <path d="M3 11h1v1H3z"/>
      <path fill="#7f4040" d="M4 11h1v1H4z"/>
      <path fill="#df9070" d="M5 11h1v1H5z"/>
      <path fill="#ffc090" d="M6 11h1v1H6z"/>
      <path fill="#df9070" d="M7 11h1v1H7zm1 0h1v1H8zm1 0h1v1H9zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#7f4040" d="M12 11h1v1h-1z"/>
      <path d="M4 12h1v1H4z"/>
      <path fill="#10203f" d="M5 12h1v1H5z"/>
      <path fill="#df9070" d="M6 12h1v1H6zm1 0h1v1H7zm1 0h1v1H8zm1 0h1v1H9z"/>
      <path fill="#7f4040" d="M10 12h1v1h-1zm1 0h1v1h-1z"/>
      <path d="M3 13h1v1H3z"/>
      <path fill="#bf985f" d="M4 13h1v1H4zm1 0h1v1H5z"/>
      <path fill="#10203f" d="M6 13h1v1H6zm1 0h1v1H7z"/>
      <path fill="#c04040" d="M8 13h1v1H8z"/>
      <path fill="#10203f" d="M9 13h1v1H9z"/>
      <path fill="#7f4040" d="M10 13h1v1h-1z"/>
      <path d="M2 14h1v1H2z"/>
      <path fill="#ffc05f" d="M3 14h1v1H3z"/>
      <path fill="#7f4040" d="M4 14h1v1H4z"/>
      <path fill="#bf985f" d="M5 14h1v1H5z"/>
      <path fill="#10203f" d="M6 14h1v1H6zm1 0h1v1H7z"/>
      <path d="M8 14h1v1H8zm1 0h1v1H9z"/>
      <path fill="#10203f" d="M10 14h1v1h-1z"/>
      <path fill="#7f4040" d="M11 14h1v1h-1z"/>
      <path d="M2 15h1v1H2z"/>
      <path fill="#bf985f" d="M3 15h1v1H3zm1 0h1v1H4z"/>
      <path fill="#10203f" d="M5 15h1v1H5zm1 0h1v1H6z"/>
      <path d="M7 15h1v1H7z"/>
      <path fill="#ffc05f" d="M8 15h1v1H8zm1 0h1v1H9z"/>
      <path d="M10 15h1v1h-1z"/>
      <path fill="#7f4040" d="M11 15h1v1h-1z"/>
      <path d="M12 15h1v1h-1zm-9 1h1v1H3z"/>
      <path fill="#bf985f" d="M4 16h1v1H4z"/>
      <path d="M5 16h1v1H5zm1 0h1v1H6zm1 0h1v1H7z"/>
      <path fill="#bf985f" d="M8 16h1v1H8zm1 0h1v1H9z"/>
      <path d="M10 16h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#7f4040" d="M12 16h1v1h-1z"/>
      <path d="M13 16h1v1h-1zM2 17h1v1H2z"/>
      <path fill="#c04040" d="M3 17h1v1H3z"/>
      <path d="M4 17h1v1H4zm1 0h1v1H5zm1 0h1v1H6zm1 0h1v1H7zm1 0h1v1H8zm1 0h1v1H9zm1 0h1v1h-1z"/>
      <path fill="#7f4040" d="M11 17h1v1h-1zm1 0h1v1h-1z"/>
      <path d="M13 17h1v1h-1zM2 18h1v1H2z"/>
      <path fill="#c04040" d="M3 18h1v1H3z"/>
      <path fill="#bf985f" d="M4 18h1v1H4z"/>
      <path d="M5 18h1v1H5zm1 0h1v1H6zm1 0h1v1H7zm1 0h1v1H8zm1 0h1v1H9z"/>
      <path fill="#7f4040" d="M10 18h1v1h-1zm1 0h1v1h-1z"/>
      <path d="M12 18h1v1h-1zm-9 1h1v1H3z"/>
      <path fill="#ff605f" d="M4 19h1v1H4zm1 0h1v1H5z"/>
      <path d="M6 19h1v1H6zm4 0h1v1h-1zm1 0h1v1h-1zm-7 1h1v1H4zm1 0h1v1H5z"/>
    </>
  );
}
