
export const ADVANCE_SCREEN_DIMENSIONS = {
  x: 240,
  y: 160,
};

export const SWAN_SCREEN_LANDSCAPE_DIMENSIONS = {
  x: 224,
  y: 144,
};

export const SWAN_SCREEN_PORTRAIT_DIMENSIONS = {
  x: SWAN_SCREEN_LANDSCAPE_DIMENSIONS.y,
  y: SWAN_SCREEN_LANDSCAPE_DIMENSIONS.x,
};

export const GEAR_SCREEN_DIMENSIONS = {
  x: 160,
  y: 144,
}

export const RS_SPRITE_HEX_COLORS = {
  black: '#000000',
  white: '#ffffff',
  lightRed: '#ff5555',
  red: '#b83838',
  lightGray: '#dadbed',
  darkGray: '#373f74',
  blue: '#293155',
  darkBlue: '#111d37',
  beige: '#ffc9a7',
  tan: '#ffb885',
  lightBrown: '#db8565',
  brown: '#743838',
  mustard: '#b78d55',
  yellow: '#ffb855',
};

export const THROTTLE_WAIT_DURATION = 150;

export const MOVEMENT_INCREMENT = 4;
