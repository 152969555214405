import React from 'react';

export default function CliffLeft() {
  return (
    <>
      <path fill="#b89088" d="M12 0h1v1h-1z"/>
      <path fill="#d8b0a0" d="M13 0h1v1h-1z"/>
      <path fill="#b89088" d="M11 1h1v1h-1z"/>
      <path fill="#d8b0a0" d="M12 1h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#b89088" d="M11 2h1v1h-1z"/>
      <path fill="#d8b0a0" d="M12 2h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#987070" d="M11 3h1v1h-1z"/>
      <path fill="#b89088" d="M12 3h1v1h-1z"/>
      <path fill="#d8b0a0" d="M13 3h1v1h-1z"/>
      <path fill="#b89088" d="M10 4h1v1h-1z"/>
      <path fill="#d8b0a0" d="M11 4h1v1h-1z"/>
      <path fill="#b89088" d="M12 4h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#d8b0a0" d="M9 5h1v1H9zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#b89088" d="M13 5h1v1h-1z"/>
      <path fill="#d8b0a0" d="M8 6h1v1H8zm1 0h1v1H9zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#b89088" d="M7 7h1v1H7z"/>
      <path fill="#d8b0a0" d="M8 7h1v1H8zm1 0h1v1H9zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#b89088" d="M6 8h1v1H6z"/>
      <path fill="#d8b0a0" d="M7 8h1v1H7z"/>
      <path fill="#b89088" d="M8 8h1v1H8zm1 0h1v1H9z"/>
      <path fill="#805858" d="M10 8h1v1h-1z"/>
      <path fill="#d8b0a0" d="M11 8h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#987070" d="M5 9h1v1H5z"/>
      <path fill="#d8b0a0" d="M6 9h1v1H6z"/>
      <path fill="#b89088" d="M7 9h1v1H7z"/>
      <path fill="#805858" d="M8 9h1v1H8zm1 0h1v1H9zm1 0h1v1h-1z"/>
      <path fill="#d8b0a0" d="M11 9h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#987070" d="M5 10h1v1H5z"/>
      <path fill="#b89088" d="M6 10h1v1H6z"/>
      <path fill="#805858" d="M7 10h1v1H7zm1 0h1v1H8zm1 0h1v1H9z"/>
      <path fill="#b89088" d="M10 10h1v1h-1z"/>
      <path fill="#d8b0a0" d="M11 10h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#987070" d="M4 11h1v1H4zm1 0h1v1H5z"/>
      <path fill="#805858" d="M6 11h1v1H6zm1 0h1v1H7zm1 0h1v1H8z"/>
      <path fill="#987070" d="M9 11h1v1H9z"/>
      <path fill="#b89088" d="M10 11h1v1h-1z"/>
      <path fill="#d8b0a0" d="M11 11h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#987070" d="M4 12h1v1H4z"/>
      <path fill="#805858" d="M5 12h1v1H5zm1 0h1v1H6zm1 0h1v1H7z"/>
      <path fill="#987070" d="M8 12h1v1H8z"/>
      <path fill="#b89088" d="M9 12h1v1H9zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#d8b0a0" d="M12 12h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#987070" d="M3 13h1v1H3zm1 0h1v1H4z"/>
      <path fill="#805858" d="M5 13h1v1H5zm1 0h1v1H6zm1 0h1v1H7z"/>
      <path fill="#b89088" d="M8 13h1v1H8zm1 0h1v1H9zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#987070" d="M12 13h1v1h-1z"/>
      <path fill="#d8b0a0" d="M13 13h1v1h-1z"/>
      <path fill="#987070" d="M2 14h1v1H2zm1 0h1v1H3z"/>
      <path fill="#805858" d="M4 14h1v1H4zm1 0h1v1H5zm1 0h1v1H6z"/>
      <path fill="#987070" d="M7 14h1v1H7z"/>
      <path fill="#b89088" d="M8 14h1v1H8zm1 0h1v1H9zm1 0h1v1h-1z"/>
      <path fill="#987070" d="M11 14h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#604050" d="M1 15h1v1H1z"/>
      <path fill="#987070" d="M2 15h1v1H2zm1 0h1v1H3z"/>
      <path fill="#805858" d="M4 15h1v1H4zm1 0h1v1H5zm1 0h1v1H6z"/>
      <path fill="#b89088" d="M7 15h1v1H7zm1 0h1v1H8zm1 0h1v1H9z"/>
      <path fill="#987070" d="M10 15h1v1h-1z"/>
      <path fill="#805858" d="M11 15h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#987070" d="M13 15h1v1h-1zM1 16h1v1H1zm1 0h1v1H2z"/>
      <path fill="#805858" d="M3 16h1v1H3zm1 0h1v1H4z"/>
      <path fill="#987070" d="M5 16h1v1H5z"/>
      <path fill="#805858" d="M6 16h1v1H6z"/>
      <path fill="#b89088" d="M7 16h1v1H7zm1 0h1v1H8z"/>
      <path fill="#987070" d="M9 16h1v1H9z"/>
      <path fill="#805858" d="M10 16h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#604050" d="M0 17h1v1H0z"/>
      <path fill="#987070" d="M1 17h1v1H1zm1 0h1v1H2z"/>
      <path fill="#805858" d="M3 17h1v1H3z"/>
      <path fill="#604050" d="M4 17h1v1H4z"/>
      <path fill="#987070" d="M5 17h1v1H5zm1 0h1v1H6z"/>
      <path fill="#805858" d="M7 17h1v1H7z"/>
      <path fill="#987070" d="M8 17h1v1H8z"/>
      <path fill="#805858" d="M9 17h1v1H9zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#604050" d="M0 18h1v1H0z"/>
      <path fill="#987070" d="M1 18h1v1H1z"/>
      <path fill="#805858" d="M2 18h1v1H2z"/>
      <path fill="#604050" d="M3 18h1v1H3z"/>
      <path fill="#987070" d="M4 18h1v1H4z"/>
      <path fill="#b89088" d="M5 18h1v1H5zm1 0h1v1H6z"/>
      <path fill="#987070" d="M7 18h1v1H7z"/>
      <path fill="#805858" d="M8 18h1v1H8zm1 0h1v1H9zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#604050" d="M0 19h1v1H0z"/>
      <path fill="#805858" d="M1 19h1v1H1z"/>
      <path fill="#604050" d="M2 19h1v1H2z"/>
      <path fill="#805858" d="M3 19h1v1H3z"/>
      <path fill="#987070" d="M4 19h1v1H4z"/>
      <path fill="#b89088" d="M5 19h1v1H5z"/>
      <path fill="#987070" d="M6 19h1v1H6z"/>
      <path fill="#604050" d="M7 19h1v1H7z"/>
      <path fill="#805858" d="M8 19h1v1H8zm1 0h1v1H9zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#604050" d="M0 20h1v1H0z"/>
      <path fill="#805858" d="M1 20h1v1H1z"/>
      <path fill="#604050" d="M2 20h1v1H2z"/>
      <path fill="#987070" d="M3 20h1v1H3zm1 0h1v1H4zm1 0h1v1H5z"/>
      <path fill="#805858" d="M6 20h1v1H6z"/>
      <path fill="#604050" d="M7 20h1v1H7zm1 0h1v1H8z"/>
      <path fill="#805858" d="M9 20h1v1H9zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#604050" d="M0 21h1v1H0zm1 0h1v1H1z"/>
      <path fill="#805858" d="M2 21h1v1H2z"/>
      <path fill="#987070" d="M3 21h1v1H3zm1 0h1v1H4z"/>
      <path fill="#805858" d="M5 21h1v1H5zm1 0h1v1H6zm1 0h1v1H7zm1 0h1v1H8z"/>
      <path fill="#604050" d="M9 21h1v1H9zm1 0h1v1h-1z"/>
      <path fill="#805858" d="M11 21h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#604050" d="M0 22h1v1H0zm1 0h1v1H1z"/>
      <path fill="#805858" d="M2 22h1v1H2zm1 0h1v1H3zm1 0h1v1H4z"/>
      <path fill="#604050" d="M5 22h1v1H5z"/>
      <path fill="#805858" d="M6 22h1v1H6zm1 0h1v1H7zm1 0h1v1H8zm1 0h1v1H9zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#604050" d="M0 23h1v1H0z"/>
      <path fill="#805858" d="M1 23h1v1H1zm1 0h1v1H2z"/>
      <path fill="#604050" d="M3 23h1v1H3zm1 0h1v1H4zm1 0h1v1H5z"/>
      <path fill="#987070" d="M6 23h1v1H6zm1 0h1v1H7zm1 0h1v1H8z"/>
      <path fill="#805858" d="M9 23h1v1H9zm1 0h1v1h-1z"/>
      <path fill="#604050" d="M11 23h1v1h-1z"/>
      <path fill="#805858" d="M12 23h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#604050" d="M0 24h1v1H0z"/>
      <path fill="#805858" d="M1 24h1v1H1z"/>
      <path fill="#604050" d="M2 24h1v1H2z"/>
      <path fill="#805858" d="M3 24h1v1H3z"/>
      <path fill="#604050" d="M4 24h1v1H4zm1 0h1v1H5z"/>
      <path fill="#987070" d="M6 24h1v1H6zm1 0h1v1H7zm1 0h1v1H8zm1 0h1v1H9z"/>
      <path fill="#805858" d="M10 24h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#604050" d="M12 24h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#805858" d="M0 25h1v1H0z"/>
      <path fill="#604050" d="M1 25h1v1H1z"/>
      <path fill="#805858" d="M2 25h1v1H2z"/>
      <path fill="#604050" d="M3 25h1v1H3z"/>
      <path fill="#805858" d="M4 25h1v1H4z"/>
      <path fill="#604050" d="M5 25h1v1H5z"/>
      <path fill="#987070" d="M6 25h1v1H6zm1 0h1v1H7zm1 0h1v1H8z"/>
      <path fill="#805858" d="M9 25h1v1H9z"/>
      <path fill="#604050" d="M10 25h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#805858" d="M0 26h1v1H0zm1 0h1v1H1z"/>
      <path fill="#604050" d="M2 26h1v1H2z"/>
      <path fill="#805858" d="M3 26h1v1H3z"/>
      <path fill="#604050" d="M4 26h1v1H4zm1 0h1v1H5z"/>
      <path fill="#805858" d="M6 26h1v1H6zm1 0h1v1H7zm1 0h1v1H8zm1 0h1v1H9z"/>
      <path fill="#604050" d="M10 26h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#987070" d="M0 27h1v1H0z"/>
      <path fill="#805858" d="M1 27h1v1H1z"/>
      <path fill="#604050" d="M2 27h1v1H2zm1 0h1v1H3zm1 0h1v1H4zm1 0h1v1H5z"/>
      <path fill="#805858" d="M6 27h1v1H6zm1 0h1v1H7zm1 0h1v1H8zm1 0h1v1H9z"/>
      <path fill="#604050" d="M10 27h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#987070" d="M1 28h1v1H1z"/>
      <path fill="#805858" d="M2 28h1v1H2zm1 0h1v1H3z"/>
      <path fill="#604050" d="M4 28h1v1H4zm1 0h1v1H5zm1 0h1v1H6z"/>
      <path fill="#805858" d="M7 28h1v1H7zm1 0h1v1H8zm1 0h1v1H9z"/>
      <path fill="#604050" d="M10 28h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#987070" d="M2 29h1v1H2z"/>
      <path fill="#805858" d="M3 29h1v1H3zm1 0h1v1H4zm1 0h1v1H5zm1 0h1v1H6z"/>
      <path fill="#604050" d="M7 29h1v1H7zm1 0h1v1H8z"/>
      <path fill="#805858" d="M9 29h1v1H9z"/>
      <path fill="#604050" d="M10 29h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#805858" d="M4 30h1v1H4zm1 0h1v1H5zm1 0h1v1H6zm1 0h1v1H7zm1 0h1v1H8z"/>
      <path fill="#604050" d="M9 30h1v1H9zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1z"/>
    </>
  );
}
