import React from 'react';

export default function SpriteLeftWalkingTwo() {
  return (
    <>
      <path fill="#3f487f" d="M8 0h1v1H8zM7 1h1v1H7z"/>
      <path fill="#fff8ff" d="M8 1h1v1H8z"/>
      <path fill="#3f487f" d="M9 1h1v1H9zM3 2h1v1H3zm1 0h1v1H4zm1 0h1v1H5zm1 0h1v1H6z"/>
      <path fill="#fff8ff" d="M7 2h1v1H7zm1 0h1v1H8z"/>
      <path fill="#3f487f" d="M9 2h1v1H9zM1 3h1v1H1zm1 0h1v1H2z"/>
      <path fill="#fff8ff" d="M3 3h1v1H3zm1 0h1v1H4z"/>
      <path fill="#dfe0ef" d="M5 3h1v1H5zm1 0h1v1H6zm1 0h1v1H7zm1 0h1v1H8zm1 0h1v1H9z"/>
      <path fill="#3f487f" d="M10 3h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#2f3860" d="M0 4h1v1H0z"/>
      <path fill="#3f487f" d="M1 4h1v1H1z"/>
      <path fill="#fff8ff" d="M2 4h1v1H2zm1 0h1v1H3zm1 0h1v1H4zm1 0h1v1H5zm1 0h1v1H6zm1 0h1v1H7z"/>
      <path fill="#dfe0ef" d="M8 4h1v1H8zm1 0h1v1H9zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#3f487f" d="M12 4h1v1h-1z"/>
      <path fill="#50506f" d="M13 4h1v1h-1z"/>
      <path fill="#10203f" d="M0 5h1v1H0z"/>
      <path fill="#ff605f" d="M1 5h1v1H1zm1 0h1v1H2z"/>
      <path fill="#2f3860" d="M3 5h1v1H3zm1 0h1v1H4z"/>
      <path fill="#dfe0ef" d="M5 5h1v1H5zm1 0h1v1H6zm1 0h1v1H7zm1 0h1v1H8zm1 0h1v1H9zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#3f487f" d="M13 5h1v1h-1z"/>
      <path fill="#c04040" d="M0 6h1v1H0zm1 0h1v1H1z"/>
      <path fill="#10203f" d="M2 6h1v1H2z"/>
      <path fill="#2f3860" d="M3 6h1v1H3zm1 0h1v1H4zm1 0h1v1H5zm1 0h1v1H6zm1 0h1v1H7zm1 0h1v1H8z"/>
      <path fill="#dfe0ef" d="M9 6h1v1H9zm1 0h1v1h-1z"/>
      <path d="M11 6h1v1h-1z"/>
      <path fill="#3f487f" d="M12 6h1v1h-1z"/>
      <path fill="#7f4040" d="M0 7h1v1H0z"/>
      <path fill="#10203f" d="M1 7h1v1H1zm1 0h1v1H2z"/>
      <path fill="#2f3860" d="M3 7h1v1H3zm1 0h1v1H4zm1 0h1v1H5zm1 0h1v1H6zm1 0h1v1H7z"/>
      <path fill="#10203f" d="M8 7h1v1H8zm1 0h1v1H9z"/>
      <path fill="#dfe0ef" d="M10 7h1v1h-1z"/>
      <path d="M11 7h1v1h-1z"/>
      <path fill="#7f4040" d="M0 8h1v1H0z"/>
      <path fill="#df9070" d="M1 8h1v1H1z"/>
      <path fill="#ffd0b0" d="M2 8h1v1H2zm1 0h1v1H3zm1 0h1v1H4z"/>
      <path fill="#10203f" d="M5 8h1v1H5zm1 0h1v1H6zm1 0h1v1H7zm1 0h1v1H8zm1 0h1v1H9zm1 0h1v1h-1z"/>
      <path d="M11 8h1v1h-1z"/>
      <path fill="#7f4040" d="M0 9h1v1H0z"/>
      <path fill="#ffd0b0" d="M1 9h1v1H1zm1 0h1v1H2z"/>
      <path fill="#10203f" d="M3 9h1v1H3z"/>
      <path fill="#ffd0b0" d="M4 9h1v1H4z"/>
      <path fill="#df9070" d="M5 9h1v1H5z"/>
      <path d="M6 9h1v1H6z"/>
      <path fill="#10203f" d="M7 9h1v1H7zm1 0h1v1H8zm1 0h1v1H9zm1 0h1v1h-1z"/>
      <path d="M11 9h1v1h-1z"/>
      <path fill="#7f4040" d="M0 10h1v1H0z"/>
      <path fill="#ffd0b0" d="M1 10h1v1H1zm1 0h1v1H2z"/>
      <path fill="#10203f" d="M3 10h1v1H3z"/>
      <path fill="#ffc090" d="M4 10h1v1H4z"/>
      <path fill="#df9070" d="M5 10h1v1H5z"/>
      <path d="M6 10h1v1H6z"/>
      <path fill="#ffc090" d="M7 10h1v1H7z"/>
      <path fill="#df9070" d="M8 10h1v1H8z"/>
      <path d="M9 10h1v1H9zm1 0h1v1h-1z"/>
      <path fill="#7f4040" d="M1 11h1v1H1z"/>
      <path fill="#df9070" d="M2 11h1v1H2zm1 0h1v1H3zm1 0h1v1H4zm1 0h1v1H5zm1 0h1v1H6z"/>
      <path fill="#ffc090" d="M7 11h1v1H7z"/>
      <path fill="#df9070" d="M8 11h1v1H8z"/>
      <path fill="#7f4040" d="M9 11h1v1H9z"/>
      <path d="M10 11h1v1h-1z"/>
      <path fill="#7f4040" d="M2 12h1v1H2zm1 0h1v1H3z"/>
      <path fill="#df9070" d="M4 12h1v1H4zm1 0h1v1H5zm1 0h1v1H6zm1 0h1v1H7z"/>
      <path fill="#10203f" d="M8 12h1v1H8z"/>
      <path d="M9 12h1v1H9z"/>
      <path fill="#7f4040" d="M3 13h1v1H3zm1 0h1v1H4z"/>
      <path fill="#c04040" d="M5 13h1v1H5z"/>
      <path fill="#10203f" d="M6 13h1v1H6z"/>
      <path fill="#c04040" d="M7 13h1v1H7z"/>
      <path fill="#bf985f" d="M8 13h1v1H8zm1 0h1v1H9z"/>
      <path d="M10 13h1v1h-1z"/>
      <path fill="#7f4040" d="M2 14h1v1H2zm1 0h1v1H3z"/>
      <path fill="#c04040" d="M4 14h1v1H4z"/>
      <path fill="#10203f" d="M5 14h1v1H5z"/>
      <path d="M6 14h1v1H6zm1 0h1v1H7z"/>
      <path fill="#bf985f" d="M8 14h1v1H8z"/>
      <path fill="#7f4040" d="M9 14h1v1H9z"/>
      <path fill="#ffc05f" d="M10 14h1v1h-1z"/>
      <path d="M11 14h1v1h-1z"/>
      <path fill="#7f4040" d="M2 15h1v1H2z"/>
      <path fill="#c04040" d="M3 15h1v1H3z"/>
      <path fill="#10203f" d="M4 15h1v1H4z"/>
      <path d="M5 15h1v1H5z"/>
      <path fill="#ffc05f" d="M6 15h1v1H6zm1 0h1v1H7z"/>
      <path d="M8 15h1v1H8z"/>
      <path fill="#bf985f" d="M9 15h1v1H9zm1 0h1v1h-1z"/>
      <path d="M11 15h1v1h-1zM1 16h1v1H1z"/>
      <path fill="#7f4040" d="M2 16h1v1H2z"/>
      <path fill="#10203f" d="M3 16h1v1H3zm1 0h1v1H4z"/>
      <path d="M5 16h1v1H5z"/>
      <path fill="#bf985f" d="M6 16h1v1H6zm1 0h1v1H7z"/>
      <path d="M8 16h1v1H8z"/>
      <path fill="#bf985f" d="M9 16h1v1H9z"/>
      <path d="M10 16h1v1h-1zM0 17h1v1H0z"/>
      <path fill="#ff605f" d="M1 17h1v1H1z"/>
      <path d="M2 17h1v1H2z"/>
      <path fill="#10203f" d="M3 17h1v1H3zm1 0h1v1H4z"/>
      <path d="M5 17h1v1H5zm1 0h1v1H6zm1 0h1v1H7zm1 0h1v1H8zm1 0h1v1H9z"/>
      <path fill="#7f4040" d="M10 17h1v1h-1z"/>
      <path d="M11 17h1v1h-1zM0 18h1v1H0z"/>
      <path fill="#ff605f" d="M1 18h1v1H1z"/>
      <path fill="#bf985f" d="M2 18h1v1H2z"/>
      <path d="M3 18h1v1H3zm1 0h1v1H4zm1 0h1v1H5zm1 0h1v1H6zm1 0h1v1H7zm1 0h1v1H8z"/>
      <path fill="#7f4040" d="M9 18h1v1H9zm1 0h1v1h-1z"/>
      <path d="M11 18h1v1h-1zM1 19h1v1H1z"/>
      <path fill="#7f4040" d="M2 19h1v1H2zm1 0h1v1H3z"/>
      <path d="M4 19h1v1H4zm5 0h1v1H9zm1 0h1v1h-1zm-8 1h1v1H2zm1 0h1v1H3z"/>
    </>
  );
}
