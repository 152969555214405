import React from 'react';

export default function SpriteFrontWalkingTwo() {
  return (
    <>
      <path fill="#3f487f" d="M9 0h1v1H9zM8 1h1v1H8z"/>
      <path fill="#fff8ff" d="M9 1h1v1H9z"/>
      <path fill="#3f487f" d="M10 1h1v1h-1zM3 2h1v1H3zm1 0h1v1H4zm1 0h1v1H5zm1 0h1v1H6zm1 0h1v1H7z"/>
      <path fill="#fff8ff" d="M8 2h1v1H8zm1 0h1v1H9z"/>
      <path fill="#3f487f" d="M10 2h1v1h-1zm1 0h1v1h-1zM3 3h1v1H3z"/>
      <path fill="#fff8ff" d="M4 3h1v1H4zm1 0h1v1H5z"/>
      <path fill="#dfe0ef" d="M6 3h1v1H6zm1 0h1v1H7zm1 0h1v1H8zm1 0h1v1H9zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#3f487f" d="M12 3h1v1h-1zM2 4h1v1H2z"/>
      <path fill="#fff8ff" d="M3 4h1v1H3zm1 0h1v1H4zm1 0h1v1H5zm1 0h1v1H6zm1 0h1v1H7zm1 0h1v1H8z"/>
      <path fill="#dfe0ef" d="M9 4h1v1H9zm1 0h1v1h-1z"/>
      <path fill="#3f487f" d="M11 4h1v1h-1z"/>
      <path d="M2 5h1v1H2z"/>
      <path fill="#10203f" d="M3 5h1v1H3z"/>
      <path fill="#ff605f" d="M4 5h1v1H4zm1 0h1v1H5z"/>
      <path fill="#10203f" d="M6 5h1v1H6zm1 0h1v1H7z"/>
      <path fill="#ff605f" d="M8 5h1v1H8zm1 0h1v1H9z"/>
      <path fill="#10203f" d="M10 5h1v1h-1z"/>
      <path d="M11 5h1v1h-1zM1 6h1v1H1z"/>
      <path fill="#10203f" d="M2 6h1v1H2zm1 0h1v1H3z"/>
      <path fill="#2f3860" d="M4 6h1v1H4z"/>
      <path fill="#c04040" d="M5 6h1v1H5zm1 0h1v1H6zm1 0h1v1H7zm1 0h1v1H8z"/>
      <path fill="#2f3860" d="M9 6h1v1H9z"/>
      <path fill="#10203f" d="M10 6h1v1h-1zm1 0h1v1h-1z"/>
      <path d="M12 6h1v1h-1zM1 7h1v1H1z"/>
      <path fill="#10203f" d="M2 7h1v1H2zm1 0h1v1H3z"/>
      <path fill="#2f3860" d="M4 7h1v1H4zm1 0h1v1H5zm1 0h1v1H6zm1 0h1v1H7zm1 0h1v1H8zm1 0h1v1H9z"/>
      <path fill="#10203f" d="M10 7h1v1h-1zm1 0h1v1h-1z"/>
      <path d="M12 7h1v1h-1z"/>
      <path fill="#7f4040" d="M0 8h1v1H0z"/>
      <path fill="#ffc090" d="M1 8h1v1H1z"/>
      <path d="M2 8h1v1H2z"/>
      <path fill="#ffd0b0" d="M3 8h1v1H3zm1 0h1v1H4zm1 0h1v1H5zm1 0h1v1H6zm1 0h1v1H7zm1 0h1v1H8zm1 0h1v1H9zm1 0h1v1h-1z"/>
      <path d="M11 8h1v1h-1z"/>
      <path fill="#ffc090" d="M12 8h1v1h-1z"/>
      <path fill="#7f4040" d="M13 8h1v1h-1zM0 9h1v1H0z"/>
      <path fill="#ffc090" d="M1 9h1v1H1z"/>
      <path fill="#7f4040" d="M2 9h1v1H2z"/>
      <path fill="#ffd0b0" d="M3 9h1v1H3zm1 0h1v1H4z"/>
      <path fill="#10203f" d="M5 9h1v1H5z"/>
      <path fill="#ffd0b0" d="M6 9h1v1H6zm1 0h1v1H7z"/>
      <path fill="#10203f" d="M8 9h1v1H8z"/>
      <path fill="#ffd0b0" d="M9 9h1v1H9zm1 0h1v1h-1z"/>
      <path fill="#7f4040" d="M11 9h1v1h-1z"/>
      <path fill="#ffc090" d="M12 9h1v1h-1z"/>
      <path fill="#7f4040" d="M13 9h1v1h-1zM1 10h1v1H1z"/>
      <path fill="#df9070" d="M2 10h1v1H2zm1 0h1v1H3z"/>
      <path fill="#ffc090" d="M4 10h1v1H4z"/>
      <path fill="#10203f" d="M5 10h1v1H5z"/>
      <path fill="#ffc090" d="M6 10h1v1H6zm1 0h1v1H7z"/>
      <path fill="#10203f" d="M8 10h1v1H8z"/>
      <path fill="#ffc090" d="M9 10h1v1H9z"/>
      <path fill="#df9070" d="M10 10h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#7f4040" d="M12 10h1v1h-1zM2 11h1v1H2z"/>
      <path fill="#df9070" d="M3 11h1v1H3zm1 0h1v1H4zm1 0h1v1H5z"/>
      <path fill="#ffc090" d="M6 11h1v1H6zm1 0h1v1H7z"/>
      <path fill="#df9070" d="M8 11h1v1H8zm1 0h1v1H9zm1 0h1v1h-1z"/>
      <path fill="#7f4040" d="M11 11h1v1h-1z"/>
      <path d="M12 11h1v1h-1zM2 12h1v1H2zm1 0h1v1H3z"/>
      <path fill="#7f4040" d="M4 12h1v1H4z"/>
      <path fill="#df9070" d="M5 12h1v1H5zm1 0h1v1H6zm1 0h1v1H7zm1 0h1v1H8z"/>
      <path fill="#7f4040" d="M9 12h1v1H9z"/>
      <path d="M10 12h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#bf985f" d="M12 12h1v1h-1z"/>
      <path fill="#7f4040" d="M13 12h1v1h-1z"/>
      <path d="M1 13h1v1H1z"/>
      <path fill="#c04040" d="M2 13h1v1H2z"/>
      <path fill="#7f4040" d="M3 13h1v1H3z"/>
      <path fill="#10203f" d="M4 13h1v1H4zm1 0h1v1H5z"/>
      <path d="M6 13h1v1H6zm1 0h1v1H7zm1 0h1v1H8z"/>
      <path fill="#10203f" d="M9 13h1v1H9z"/>
      <path d="M10 13h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#bf985f" d="M12 13h1v1h-1z"/>
      <path fill="#10203f" d="M13 13h1v1h-1zM1 14h1v1H1z"/>
      <path d="M2 14h1v1H2zm1 0h1v1H3z"/>
      <path fill="#10203f" d="M4 14h1v1H4zm1 0h1v1H5z"/>
      <path fill="#c04040" d="M6 14h1v1H6zm1 0h1v1H7z"/>
      <path fill="#10203f" d="M8 14h1v1H8zm1 0h1v1H9zm1 0h1v1h-1z"/>
      <path d="M11 14h1v1h-1z"/>
      <path fill="#10203f" d="M12 14h1v1h-1z"/>
      <path d="M1 15h1v1H1z"/>
      <path fill="#ffc05f" d="M2 15h1v1H2zm1 0h1v1H3z"/>
      <path d="M4 15h1v1H4z"/>
      <path fill="#10203f" d="M5 15h1v1H5zm1 0h1v1H6zm1 0h1v1H7zm1 0h1v1H8z"/>
      <path fill="#7f4040" d="M9 15h1v1H9z"/>
      <path d="M10 15h1v1h-1zm-9 1h1v1H1z"/>
      <path fill="#bf985f" d="M2 16h1v1H2zm1 0h1v1H3z"/>
      <path d="M4 16h1v1H4z"/>
      <path fill="#c04040" d="M5 16h1v1H5z"/>
      <path fill="#10203f" d="M6 16h1v1H6z"/>
      <path fill="#7f4040" d="M7 16h1v1H7zm1 0h1v1H8z"/>
      <path d="M9 16h1v1H9zm1 0h1v1h-1zm-8 1h1v1H2zm1 0h1v1H3zm1 0h1v1H4zm1 0h1v1H5zm1 0h1v1H6zm1 0h1v1H7zm1 0h1v1H8zm1 0h1v1H9z"/>
      <path fill="#10203f" d="M10 17h1v1h-1z"/>
      <path d="M4 18h1v1H4zm1 0h1v1H5zm1 0h1v1H6z"/>
      <path fill="#7f4040" d="M7 18h1v1H7z"/>
      <path fill="#bf985f" d="M8 18h1v1H8z"/>
      <path fill="#7f4040" d="M9 18h1v1H9z"/>
      <path d="M10 18h1v1h-1zm-4 1h1v1H6z"/>
      <path fill="#7f4040" d="M7 19h1v1H7z"/>
      <path fill="#c04040" d="M8 19h1v1H8z"/>
      <path fill="#7f4040" d="M9 19h1v1H9z"/>
      <path d="M10 19h1v1h-1zm-3 1h1v1H7zm1 0h1v1H8zm1 0h1v1H9z"/>
    </>
  );
}
