import React from 'react';

export default function SpriteBackWalkingTwo() {
  return (
    <>
      <path fill="#3f487f" d="M4 0h1v1H4zM3 1h1v1H3z"/>
      <path fill="#fff8ff" d="M4 1h1v1H4z"/>
      <path fill="#3f487f" d="M5 1h1v1H5zM2 2h1v1H2zm1 0h1v1H3z"/>
      <path fill="#fff8ff" d="M4 2h1v1H4zm1 0h1v1H5z"/>
      <path fill="#3f487f" d="M6 2h1v1H6zm1 0h1v1H7zm1 0h1v1H8zm1 0h1v1H9zM1 3h1v1H1z"/>
      <path fill="#dfe0ef" d="M2 3h1v1H2zm1 0h1v1H3zm1 0h1v1H4zm1 0h1v1H5zm1 0h1v1H6zm1 0h1v1H7z"/>
      <path fill="#fff8ff" d="M8 3h1v1H8zm1 0h1v1H9z"/>
      <path fill="#3f487f" d="M10 3h1v1h-1zM2 4h1v1H2z"/>
      <path fill="#dfe0ef" d="M3 4h1v1H3zm1 0h1v1H4z"/>
      <path fill="#fff8ff" d="M5 4h1v1H5zm1 0h1v1H6zm1 0h1v1H7zm1 0h1v1H8zm1 0h1v1H9zm1 0h1v1h-1z"/>
      <path fill="#3f487f" d="M11 4h1v1h-1zM1 5h1v1H1z"/>
      <path fill="#dfe0ef" d="M2 5h1v1H2zm1 0h1v1H3zm1 0h1v1H4zm1 0h1v1H5zm1 0h1v1H6z"/>
      <path fill="#fff8ff" d="M7 5h1v1H7z"/>
      <path fill="#dfe0ef" d="M8 5h1v1H8z"/>
      <path fill="#fff8ff" d="M9 5h1v1H9zm1 0h1v1h-1z"/>
      <path fill="#dfe0ef" d="M11 5h1v1h-1z"/>
      <path fill="#3f487f" d="M12 5h1v1h-1z"/>
      <path d="M1 6h1v1H1z"/>
      <path fill="#dfe0ef" d="M2 6h1v1H2zm1 0h1v1H3zm1 0h1v1H4zm1 0h1v1H5zm1 0h1v1H6zm1 0h1v1H7zm1 0h1v1H8zm1 0h1v1H9zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path d="M12 6h1v1h-1zM1 7h1v1H1z"/>
      <path fill="#10203f" d="M2 7h1v1H2z"/>
      <path fill="#dfe0ef" d="M3 7h1v1H3zm1 0h1v1H4zm1 0h1v1H5zm1 0h1v1H6zm1 0h1v1H7zm1 0h1v1H8zm1 0h1v1H9zm1 0h1v1h-1z"/>
      <path fill="#10203f" d="M11 7h1v1h-1z"/>
      <path d="M12 7h1v1h-1z"/>
      <path fill="#7f4040" d="M0 8h1v1H0z"/>
      <path fill="#df9070" d="M1 8h1v1H1z"/>
      <path d="M2 8h1v1H2z"/>
      <path fill="#dfe0ef" d="M3 8h1v1H3z"/>
      <path fill="#10203f" d="M4 8h1v1H4z"/>
      <path fill="#dfe0ef" d="M5 8h1v1H5zm1 0h1v1H6zm1 0h1v1H7zm1 0h1v1H8z"/>
      <path fill="#10203f" d="M9 8h1v1H9z"/>
      <path fill="#dfe0ef" d="M10 8h1v1h-1z"/>
      <path d="M11 8h1v1h-1z"/>
      <path fill="#df9070" d="M12 8h1v1h-1z"/>
      <path fill="#7f4040" d="M13 8h1v1h-1zM0 9h1v1H0z"/>
      <path fill="#df9070" d="M1 9h1v1H1z"/>
      <path fill="#7f4040" d="M2 9h1v1H2z"/>
      <path fill="#10203f" d="M3 9h1v1H3zm1 0h1v1H4zm1 0h1v1H5z"/>
      <path fill="#dfe0ef" d="M6 9h1v1H6zm1 0h1v1H7z"/>
      <path fill="#10203f" d="M8 9h1v1H8zm1 0h1v1H9zm1 0h1v1h-1z"/>
      <path fill="#7f4040" d="M11 9h1v1h-1z"/>
      <path fill="#df9070" d="M12 9h1v1h-1z"/>
      <path fill="#7f4040" d="M13 9h1v1h-1zM1 10h1v1H1z"/>
      <path fill="#df9070" d="M2 10h1v1H2zm1 0h1v1H3z"/>
      <path d="M4 10h1v1H4zm1 0h1v1H5zm1 0h1v1H6zm1 0h1v1H7zm1 0h1v1H8zm1 0h1v1H9z"/>
      <path fill="#df9070" d="M10 10h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#7f4040" d="M12 10h1v1h-1z"/>
      <path d="M1 11h1v1H1z"/>
      <path fill="#7f4040" d="M2 11h1v1H2z"/>
      <path fill="#df9070" d="M3 11h1v1H3zm1 0h1v1H4z"/>
      <path fill="#ffc090" d="M5 11h1v1H5zm1 0h1v1H6zm1 0h1v1H7zm1 0h1v1H8z"/>
      <path fill="#df9070" d="M9 11h1v1H9zm1 0h1v1h-1z"/>
      <path fill="#7f4040" d="M11 11h1v1h-1z"/>
      <path d="M1 12h1v1H1z"/>
      <path fill="#bf985f" d="M2 12h1v1H2z"/>
      <path fill="#10203f" d="M3 12h1v1H3zm1 0h1v1H4z"/>
      <path fill="#7f4040" d="M5 12h1v1H5zm1 0h1v1H6zm1 0h1v1H7zm1 0h1v1H8z"/>
      <path fill="#c04040" d="M9 12h1v1H9z"/>
      <path fill="#10203f" d="M10 12h1v1h-1z"/>
      <path d="M2 13h1v1H2zm1 0h1v1H3z"/>
      <path fill="#10203f" d="M4 13h1v1H4z"/>
      <path d="M5 13h1v1H5zm1 0h1v1H6zm1 0h1v1H7zm1 0h1v1H8z"/>
      <path fill="#10203f" d="M9 13h1v1H9z"/>
      <path fill="#c04040" d="M10 13h1v1h-1z"/>
      <path d="M11 13h1v1h-1zm-9 1h1v1H2zm1 0h1v1H3zm1 0h1v1H4z"/>
      <path fill="#bf985f" d="M5 14h1v1H5z"/>
      <path fill="#fff8ff" d="M6 14h1v1H6zm1 0h1v1H7z"/>
      <path fill="#bf985f" d="M8 14h1v1H8z"/>
      <path d="M9 14h1v1H9z"/>
      <path fill="#7f4040" d="M10 14h1v1h-1z"/>
      <path d="M11 14h1v1h-1zm-9 1h1v1H2zm1 0h1v1H3z"/>
      <path fill="#7f4040" d="M4 15h1v1H4z"/>
      <path fill="#ffc05f" d="M5 15h1v1H5zm1 0h1v1H6zm1 0h1v1H7zm1 0h1v1H8z"/>
      <path fill="#7f4040" d="M9 15h1v1H9z"/>
      <path d="M10 15h1v1h-1z"/>
      <path fill="#ffc05f" d="M11 15h1v1h-1z"/>
      <path fill="#7f4040" d="M12 15h1v1h-1zM2 16h1v1H2z"/>
      <path d="M3 16h1v1H3z"/>
      <path fill="#7f4040" d="M4 16h1v1H4zm1 0h1v1H5zm1 0h1v1H6zm1 0h1v1H7zm1 0h1v1H8zm1 0h1v1H9z"/>
      <path d="M10 16h1v1h-1z"/>
      <path fill="#bf985f" d="M11 16h1v1h-1z"/>
      <path fill="#10203f" d="M12 16h1v1h-1z"/>
      <path d="M3 17h1v1H3zm1 0h1v1H4z"/>
      <path fill="#bf985f" d="M5 17h1v1H5zm1 0h1v1H6zm1 0h1v1H7zm1 0h1v1H8z"/>
      <path d="M9 17h1v1H9zm1 0h1v1h-1z"/>
      <path fill="#10203f" d="M11 17h1v1h-1z"/>
      <path d="M3 18h1v1H3zm1 0h1v1H4zm1 0h1v1H5zm1 0h1v1H6zm1 0h1v1H7zm1 0h1v1H8zm1 0h1v1H9zm-6 1h1v1H3z"/>
      <path fill="#c04040" d="M4 19h1v1H4z"/>
      <path fill="#7f4040" d="M5 19h1v1H5zm1 0h1v1H6z"/>
      <path d="M7 19h1v1H7zm-3 1h1v1H4zm1 0h1v1H5zm1 0h1v1H6z"/>
    </>
  );
}
