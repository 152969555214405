import React from 'react';

export default function CliffFront() {
  return (
    <>
      <path fill="#987070" d="M0 0h1v1H0z"/>
      <path fill="#b89088" d="M1 0h1v1H1zm1 0h1v1H2z"/>
      <path fill="#d8b0a0" d="M3 0h1v1H3zm1 0h1v1H4zm1 0h1v1H5z"/>
      <path fill="#987070" d="M6 0h1v1H6zm1 0h1v1H7z"/>
      <path fill="#604050" d="M8 0h1v1H8z"/>
      <path fill="#805858" d="M9 0h1v1H9zm1 0h1v1h-1z"/>
      <path fill="#d8b0a0" d="M11 0h1v1h-1z"/>
      <path fill="#987070" d="M12 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#b89088" d="M15 0h1v1h-1z"/>
      <path fill="#805858" d="M0 1h1v1H0z"/>
      <path fill="#987070" d="M1 1h1v1H1z"/>
      <path fill="#b89088" d="M2 1h1v1H2zm1 0h1v1H3z"/>
      <path fill="#987070" d="M4 1h1v1H4zm1 0h1v1H5zm1 0h1v1H6zm1 0h1v1H7z"/>
      <path fill="#805858" d="M8 1h1v1H8zm1 0h1v1H9zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#987070" d="M12 1h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#805858" d="M15 1h1v1h-1zM0 2h1v1H0zm1 0h1v1H1z"/>
      <path fill="#987070" d="M2 2h1v1H2zm1 0h1v1H3zm1 0h1v1H4zm1 0h1v1H5zm1 0h1v1H6z"/>
      <path fill="#604050" d="M7 2h1v1H7z"/>
      <path fill="#805858" d="M8 2h1v1H8zm1 0h1v1H9zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#987070" d="M12 2h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#805858" d="M14 2h1v1h-1zm1 0h1v1h-1zM0 3h1v1H0zm1 0h1v1H1zm1 0h1v1H2z"/>
      <path fill="#987070" d="M3 3h1v1H3zm1 0h1v1H4z"/>
      <path fill="#604050" d="M5 3h1v1H5zm1 0h1v1H6zm1 0h1v1H7z"/>
      <path fill="#805858" d="M8 3h1v1H8zm1 0h1v1H9zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1zM0 4h1v1H0zm1 0h1v1H1zm1 0h1v1H2zm1 0h1v1H3z"/>
      <path fill="#604050" d="M4 4h1v1H4zm1 0h1v1H5zm1 0h1v1H6zm1 0h1v1H7z"/>
      <path fill="#805858" d="M8 4h1v1H8zm1 0h1v1H9zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1zM0 5h1v1H0zm1 0h1v1H1zm1 0h1v1H2zm1 0h1v1H3z"/>
      <path fill="#604050" d="M4 5h1v1H4zm1 0h1v1H5zm1 0h1v1H6zm1 0h1v1H7z"/>
      <path fill="#805858" d="M8 5h1v1H8zm1 0h1v1H9zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#604050" d="M12 5h1v1h-1z"/>
      <path fill="#805858" d="M13 5h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#604050" d="M0 6h1v1H0z"/>
      <path fill="#805858" d="M1 6h1v1H1zm1 0h1v1H2zm1 0h1v1H3z"/>
      <path fill="#604050" d="M4 6h1v1H4zm1 0h1v1H5zm1 0h1v1H6zm1 0h1v1H7z"/>
      <path fill="#805858" d="M8 6h1v1H8zm1 0h1v1H9zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#604050" d="M13 6h1v1h-1z"/>
      <path fill="#805858" d="M14 6h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#604050" d="M0 7h1v1H0z"/>
      <path fill="#805858" d="M1 7h1v1H1zm1 0h1v1H2zm1 0h1v1H3z"/>
      <path fill="#604050" d="M4 7h1v1H4zm1 0h1v1H5zm1 0h1v1H6zm1 0h1v1H7z"/>
      <path fill="#805858" d="M8 7h1v1H8z"/>
      <path fill="#987070" d="M9 7h1v1H9z"/>
      <path fill="#805858" d="M10 7h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#604050" d="M12 7h1v1h-1z"/>
      <path fill="#805858" d="M13 7h1v1h-1z"/>
      <path fill="#604050" d="M14 7h1v1h-1z"/>
      <path fill="#805858" d="M15 7h1v1h-1z"/>
      <path fill="#604050" d="M0 8h1v1H0z"/>
      <path fill="#805858" d="M1 8h1v1H1zm1 0h1v1H2zm1 0h1v1H3zm1 0h1v1H4z"/>
      <path fill="#604050" d="M5 8h1v1H5zm1 0h1v1H6z"/>
      <path fill="#805858" d="M7 8h1v1H7z"/>
      <path fill="#987070" d="M8 8h1v1H8zm1 0h1v1H9zm1 0h1v1h-1z"/>
      <path fill="#805858" d="M11 8h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#604050" d="M13 8h1v1h-1z"/>
      <path fill="#805858" d="M14 8h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#604050" d="M0 9h1v1H0z"/>
      <path fill="#805858" d="M1 9h1v1H1zm1 0h1v1H2zm1 0h1v1H3zm1 0h1v1H4z"/>
      <path fill="#604050" d="M5 9h1v1H5zm1 0h1v1H6z"/>
      <path fill="#987070" d="M7 9h1v1H7zm1 0h1v1H8zm1 0h1v1H9zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#805858" d="M12 9h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#604050" d="M14 9h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#805858" d="M0 10h1v1H0zm1 0h1v1H1zm1 0h1v1H2zm1 0h1v1H3zm1 0h1v1H4z"/>
      <path fill="#604050" d="M5 10h1v1H5z"/>
      <path fill="#805858" d="M6 10h1v1H6zm1 0h1v1H7z"/>
      <path fill="#987070" d="M8 10h1v1H8zm1 0h1v1H9zm1 0h1v1h-1z"/>
      <path fill="#805858" d="M11 10h1v1h-1z"/>
      <path fill="#604050" d="M12 10h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#805858" d="M0 11h1v1H0zm1 0h1v1H1zm1 0h1v1H2zm1 0h1v1H3zm1 0h1v1H4zm1 0h1v1H5zm1 0h1v1H6zm1 0h1v1H7zm1 0h1v1H8zm1 0h1v1H9zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#604050" d="M12 11h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#805858" d="M0 12h1v1H0zm1 0h1v1H1zm1 0h1v1H2zm1 0h1v1H3zm1 0h1v1H4zm1 0h1v1H5z"/>
      <path fill="#604050" d="M6 12h1v1H6z"/>
      <path fill="#805858" d="M7 12h1v1H7zm1 0h1v1H8zm1 0h1v1H9zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#604050" d="M12 12h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#805858" d="M0 13h1v1H0zm1 0h1v1H1zm1 0h1v1H2zm1 0h1v1H3zm1 0h1v1H4z"/>
      <path fill="#604050" d="M5 13h1v1H5zm1 0h1v1H6zm1 0h1v1H7zm1 0h1v1H8z"/>
      <path fill="#805858" d="M9 13h1v1H9zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#604050" d="M12 13h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1z"/>
      <path fill="#805858" d="M0 14h1v1H0zm1 0h1v1H1zm1 0h1v1H2zm1 0h1v1H3z"/>
      <path fill="#604050" d="M4 14h1v1H4z"/>
      <path fill="#805858" d="M5 14h1v1H5zm1 0h1v1H6zm1 0h1v1H7zm1 0h1v1H8z"/>
      <path fill="#604050" d="M9 14h1v1H9zm1 0h1v1h-1z"/>
      <path fill="#805858" d="M11 14h1v1h-1z"/>
      <path fill="#604050" d="M12 14h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1zM0 15h1v1H0zm1 0h1v1H1zm1 0h1v1H2zm1 0h1v1H3z"/>
      <path fill="#805858" d="M4 15h1v1H4zm1 0h1v1H5zm1 0h1v1H6zm1 0h1v1H7zm1 0h1v1H8zm1 0h1v1H9zm1 0h1v1h-1z"/>
      <path fill="#604050" d="M11 15h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1zm1 0h1v1h-1z"/>
    </>
  );
}
